<template>
  <div class="container container-narrow">
    <div class="welcome-flow-container">
      <div class="row align-items-center">

        <div class="col-12 mt-5">
          <div class="text-center">
            <img src="~@/assets/excelerate-logo.png" alt="Excelerate" title="Excelerate" class="logo">
          </div>
          <div class="widget mb-3 mt-4">
            <div class="widget-splash splash-line"></div>
            <div class="widget-body">
              <h4>Request New Password</h4>
              <form v-if="!user.userId" @submit.prevent="login">
                  <div class="form-group">
                      <label>Email</label>
                      <input type="email" v-model="email" @keyup="emailEntry" name="email" class="form-control form-control-lg" autocomplete="email" placeholder="Enter your email" autofocus required>
                  </div>
                  <div class="alert alert-success" v-if="user.isNewPasswordRequested">
                      {{user.status}}
                  </div>
                  <div class="alert alert-danger" v-if="user.error">
                      {{ user.status }}
                  </div>
                  <div class="form-group">
                      <button class="btn btn-primary btn-block btn-lg">Request new password</button>
                  </div>
              </form>
              <div v-if="user.userId">
                  <h4>Welcome back</h4>
                  <p>You are signed in.</p>
                  <div class="mt-2">
                      <router-link to="/" class="btn btn-primary">Go to dashboard</router-link>
                  </div>
              </div>

           
            </div>
          </div><!-- /widget -->

            <div class="widget-footer-note" v-if="!user.userId">
              Don't have an account? Sign up as <router-link to="/student-signup" class="color-first">Student</router-link>/<router-link to="/professional-signup" class="color-first">Professional</router-link>
            </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import firebase from 'firebase/app';
export default {
    name: 'SignInForm',


    data: function() {
        return {
            email: '', //cw@bywerner.com
            password: '', //123456
            failureMessage: '',
            successMessage: '',
            // passwordConfirm: ''
        }
    },
    metaInfo:{ 
      title: "Forgot password – Excelerate profile | Excelerate.dk",
      meta: [
        { name: 'description', content:  'Forgot your password for your Excelerate profile? Go to Excelerate forgot password page and receive a new password in your email.'},
        { property: 'og:title', content: "Forgot password – Excelerate profile | Excelerate.dk"},
        { property: 'og:description', content: "Forgot your password for your Excelerate profile? Go to Excelerate forgot password page and receive a new password in your email."},
      ]
    },
    computed: { 
      ...mapState(['user'])
    },
    methods: {
      emailEntry(){
        this.user.status = '';
        this.user.isNewPasswordRequested = null;
        this.user.error = null;
      },
        login() {
            const user = { 
                email: this.email,
            }
            this.$store.dispatch('forgotPassword', user);

        },
        getUserByIdFromFirebase() {
            this.$store.dispatch('syncUserByIdFromFirebaseAuth');
        },
        signOut() {
            this.$store.dispatch('signOutAction');
        },

    }
}
</script>